import {
  Add,
  Global, Home, Judge, Location, Notepad2, SearchNormal1, Star1, UserCirlceAdd,
} from 'iconsax-react';
import { useEffect, useState } from 'react';
import { addDoc, collection, getDocs } from 'firebase/firestore';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { Center, Container, Screen } from '../Home/styles';
import * as styles from './styles';
import ModalInput from '../../components/ModalInput';
import Footer from '../../components/Footer';
import Input from '../../assets/styles/Input';
import { Span } from '../../assets/styles/Text';
import { db } from '../../service/firebaseConnection';
import useUser from '../../store/User';

export default function Kits() {
  const { user } = useUser();
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [request, setRequest] = useState([]);
  const [nameKit, setNameKit] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [ref, setRef] = useState('all');

  const optionsLocal = [
    {
      name1: 'Todos',
      icon1: <Global size="20" />,
      ref1: 'all',

      name2: 'Registro Civil',
      icon2: <UserCirlceAdd size="20" />,
      ref2: 'civil',
    },
    {
      name1: 'Registro de Imóveis',
      icon1: <Home size="20" />,
      ref1: 'imoveis',

      name2: 'Notas',
      icon2: <Notepad2 size="20" />,
      ref2: 'notas',
    },
    {
      name1: 'Protesto',
      icon1: <Judge size="20" />,
      ref1: 'protesto',

      name2: 'Federais',
      icon2: <Location size="20" />,
      ref2: 'federais',
    },
    {
      name1: 'Estaduais',
      icon1: <Location size="20" />,
      ref1: 'estaduais',

      name2: 'Municipais',
      icon2: <Location size="20" />,
      ref2: 'municipais',
    },
  ];

  useEffect(() => {
    const listSolicitation = async () => {
      try {
        const solicitationRef = collection(db, 'solicitation');
        const querySnapshot = await getDocs(solicitationRef);

        if (!querySnapshot.empty) {
          const solicitationList = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setOptions(solicitationList);
        }
      } catch (error) {
        console.log(error);
      }
    };

    listSolicitation();
  }, []);

  const handleSelectOption = (option) => {
    setRequest((prevRequest) => {
      if (prevRequest.some((req) => req.id === option.id)) {
        return prevRequest.filter((req) => req.id !== option.id); // Remove a opção se já estiver selecionada
      }
      return [...prevRequest, option]; // Adiciona a nova opção
    });
  };

  const handleCreateKit = async () => {
    console.log({ nameKit, request });

    if (nameKit === '') {
      toast.error('Prencha o nome do kit');
      return null;
    }

    if (request.length <= 0) {
      toast.error('Selecione as certidões');
      return null;
    }

    const kits = {
      userUid: user.uid,
      nameKit,
      request,
    };

    try {
      const kitRef = collection(db, 'kits');
      await addDoc(kitRef, kits);

      toast.success('Kit adicionado com sucesso!');
      navigate('/mykits');
    } catch (error) {
      toast.error('Erro ao adicionar o kit');
      console.error('Erro ao adicionar o kit: ', error);
    }
  };

  const listOptions = (element) => {
    const listIcon = (search) => {
      switch (search) {
        case 'civil':
          return <UserCirlceAdd size="24" />;

        case 'imoveis':
          return <Home size="24" />;

        case 'notas':
          return <Notepad2 size="24" />;

        case 'protesto':
          return <Judge size="24" />;

        default:
          return <Location size="24" />;
      }
    };

    if (ref === element.option || ref === 'all') {
      if (element.name.toLowerCase().includes(searchInput.toLowerCase())) {
        return (
          <styles.SearchOption
            key={element.id}
            onClick={() => handleSelectOption(element)}
            select={request?.some((req) => req.name === element.name)}
          >
            <div>
              {listIcon(element.option)}
              <Span>{element.name}</Span>
            </div>
            <Star1 size="24" />
          </styles.SearchOption>
        );
      }
    }
    return null;
  };

  return (
    <Screen>
      <Menu />

      <Container>
        <Header text="Crie seu kit" />

        <Center>
          <styles.Container>
            <ModalInput name="Nome para seu kit">
              <Input
                placeholder="Digite um nome para seu kit"
                onChange={(e) => setNameKit(e.target.value)}
                value={nameKit}
              />
            </ModalInput>

            <styles.OrderOptions>

              {optionsLocal.map((element) => (
                <styles.GroupOptions>
                  <styles.Options
                    onClick={() => setRef(element.ref1)}
                    select={ref === element.ref1}
                  >
                    {element.icon1}
                    <Span>{element.name1}</Span>
                  </styles.Options>

                  <styles.Options
                    onClick={() => setRef(element.ref2)}
                    select={ref === element.ref2}
                  >
                    {element.icon2}
                    <Span>{element.name2}</Span>
                  </styles.Options>
                </styles.GroupOptions>
              ))}

            </styles.OrderOptions>

            <styles.SearchInput>
              <input
                type="text"
                placeholder="Pesquise qual certidão precisa"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <button type="button"><SearchNormal1 size="24" /></button>
            </styles.SearchInput>

            <styles.GroupSearch>
              {options.map((element) => listOptions(element))}
            </styles.GroupSearch>

            <styles.Button onClick={handleCreateKit}>
              <Span bold>Criar kit</Span>
              {' '}
              <Add size="24" />
            </styles.Button>
          </styles.Container>
        </Center>

        <Footer />
      </Container>
    </Screen>
  );
}
