import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  a{
    width: 100%;
    height: 100%;
    padding: 24px 16px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-shadow: 0px 0px 8px 0px rgba(80, 0, 0, 0.25);

    svg{
      color: ${({ theme }) => theme.colors.primary.main};
    }

    span, p{
      color: ${({ theme }) => theme.colors.black};
    }

    &:hover{
      transition: 0.7s;
      background-color: rgba(256, 256, 256, 0.2);
    }
  }
`;
