import { ClipboardExport, DirectInbox, DollarSquare } from 'iconsax-react';
import { useEffect, useState } from 'react';
import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import Empty from '../../components/Empty';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Title from '../../components/Title';
import { Center, Container, Screen } from '../Home/styles';
import * as styles from './styles';
import useUser from '../../store/User';
import { db } from '../../service/firebaseConnection';
import Filter from '../../components/Filter';
import { TableList } from '../Cart/styles';
import { Span } from '../../assets/styles/Text';
import Footer from '../../components/Footer';

export default function Finance() {
  const { user } = useUser();
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const fetchApplicationsFromFirestore = async () => {
      try {
        const applicationsRef = collection(db, 'applications');
        const q = query(applicationsRef, where('userUid', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const applicationsList = querySnapshot.docs.map((doc) => doc.data());
        return setApplications(applicationsList);
      } catch (error) {
        console.error('Erro ao buscar dados do Firestore: ', error);
        return false; // Retorna um array vazio em caso de erro
      }
    };

    fetchApplicationsFromFirestore();
  }, []);

  const verifStatus = (status) => {
    switch (status) {
      case 'pending':
        return <Span className="pending">Pendente</Span>;

      case 'process':
        return <Span className="process">Processando</Span>;

      case 'success':
        return <Span className="success">Sucesso</Span>;

      default:
        return null;
    }
  };

  return (
    <Screen>
      <Menu />

      <Container>

        <Header text="Financeiro" />

        <Center>

          {!applications && (
            <Empty
              icon={<DollarSquare size="60" variant="Bulk" />}
              title="Nenhum pedido encontrado"
              text="Parece que você não efetuou nenhum pedido, faça isso agora mesmo"
              to="/order"
              link="Realizar pedido"
            />
          )}

          {applications && applications.length <= 0 && (
            <Empty
              icon={<DollarSquare size="60" variant="Bulk" />}
              title="Nenhum pedido encontrado"
              text="Parece que você não efetuou nenhum pedido, faça isso agora mesmo"
              to="/order"
              link="Realizar pedido"
            />
          )}

          {applications && applications.length > 0 && (
            <styles.Container>
              <Title
                title="Dados do seu faturamento"
                text="Prosseguir com os dados para fazer a analise"
              />

              <Filter />

              <TableList>
                <thead>
                  <tr>
                    <th>Nº</th>
                    <th>Nome do pedido</th>
                    <th>Data</th>
                    <th>Valor</th>
                    <th>Status</th>
                    <th>#</th>
                  </tr>
                </thead>

                <tbody>
                  {applications.map(({
                    orderNumber, nameOrder, date, value, status,
                  }) => (
                    <tr>
                      <td>{orderNumber}</td>
                      <td>{nameOrder}</td>
                      <td>{date}</td>
                      <td>
                        R$
                        {' '}
                        {value}
                      </td>
                      <td>{verifStatus(status)}</td>
                      <td>
                        <button type="button" className="yellow"><ClipboardExport size="20px" /></button>
                        <button type="button" className="grey"><DirectInbox size="20px" /></button>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </TableList>

              <styles.Print>
                <Span>
                  Fatura total:
                  <b>R$999,99</b>
                </Span>

                <button type="button">
                  <Span bold>Imprimir</Span>
                </button>
              </styles.Print>
            </styles.Container>
          )}
        </Center>

        <Footer />
      </Container>
    </Screen>
  );
}
