import styled, { css } from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 48px;
  border-radius: 4px;
  border: 0;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.white};
  margin-left: auto;

  ${({ disabled }) => disabled && css`
    background-color: ${({ theme }) => theme.colors.primary.lighest};

    & *{
      opacity: 0.5;
    }
  `}

  &:hover{
    transition: 0.7s;
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

export const ContainerSeparet = styled.article`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 32px;
`;

export const ContainerSelect = styled.section`
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const GroupSelect = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const AllSelect = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  gap: 8px;

  input{
    width: 16px;
    height: 16px;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.black};
  }
`;

export const PeopleSelect = styled.ul`
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0px 0px 8px 0px rgba(80, 0, 0, 0.25);

  ${({ select }) => select && css`
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
  `}

  li{
    display: flex;
    gap: 8px;
  }
`;

export const ContainerOrder = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  > span{
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }
`;

export const OrderOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const GroupOptions = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

export const Options = styled.button`
  width: 50%;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};

  svg{
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ select }) => select && css`
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0px 0px 8px 0px rgba(80, 0, 0, 0.25);
    color: ${({ theme }) => theme.colors.primary.main};

    svg{
      color: ${({ theme }) => theme.colors.primary.main};
    }
  `}
`;

export const ContainerSearch = styled.div`
  width: 100%;
  margin-top: 28px;
`;

export const SearchInput = styled.div`
  width: 100%;
  display: flex;

  input{
    width: 95%;
    background-color: ${({ theme }) => theme.colors.contrast.lighest};
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    border-radius: 8px 0px 0px 8px;
  }

  button{
    width: 5%;
    border: 0;
    background-color: ${({ theme }) => theme.colors.white} !important;
    border-radius: 0px 8px 8px 0px;

    &:hover{
      transition: 0.7s;
      background-color: rgba(256, 256, 256, 0.5);
    }
  }
`;

export const GroupSearch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 12px;
`;

export const SearchOption = styled.button`
  width: 100%;
  border-radius: 8px;
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ select }) => select && css`
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0px 0px 8px 0px rgba(80, 0, 0, 0.25);

    & * {
      color: ${({ theme }) => theme.colors.primary.main};
      stroke: ${({ theme }) => theme.colors.primary.main};
    }
  `}

  svg{
    stroke: ${({ theme }) => theme.colors.black};
  }

  > div{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const GrupPopup = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
