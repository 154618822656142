import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Sora', sans-serif;
    font-weight: 300;
  }

  html, body, #root{
    display: flex;
    flex-direction: column;
    height: 100%;
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.outhers.background};
  }

  button, a{
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
`;
