import {
  Add,
  DocumentCopy,
  Edit,
  Trash,
} from 'iconsax-react';
import { useEffect, useState } from 'react';
import {
  collection, deleteDoc, doc, getDocs, query, where,
} from 'firebase/firestore';
import { toast } from 'sonner';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { Center, Container, Screen } from '../Home/styles';
import Footer from '../../components/Footer';
import Filter from '../../components/Filter';
import { TableList } from '../Cart/styles';
import Empty from '../../components/Empty';
import { db } from '../../service/firebaseConnection';
import useUser from '../../store/User';
import useOrder from '../../store/Application';

export default function MyKits() {
  const { user } = useUser();
  const { setRequest } = useOrder();
  const navigate = useNavigate();
  const [kits, setKits] = useState([]);

  useEffect(() => {
    const fetchKits = async () => {
      try {
        const kitRef = collection(db, 'kits');
        const q = query(kitRef, where('userUid', '==', user.uid));
        const querySnapshot = await getDocs(q);

        const kitList = querySnapshot.docs.map((dc) => ({
          id: dc.id,
          ...dc.data(),
        }));

        setKits(kitList);
      } catch (error) {
        console.error('Erro ao buscar kits: ', error);
      }
    };

    fetchKits();
  }, []);

  const handleDeleteKit = async (kitId) => {
    try {
      const kitRef = doc(db, 'kits', kitId);
      await deleteDoc(kitRef);
      setKits((prevKits) => prevKits.filter((kit) => kit.id !== kitId));
      toast.success('Kit deletado com sucesso!');
    } catch (error) {
      toast.error('Erro ao deletar o kit');
      console.error('Erro ao deletar o kit: ', error);
    }
  };

  const handleEditKit = (kit) => {
    setRequest(kit);
    navigate('/kitorder'); // Substitua com a rota da página desejada
  };

  return (
    <Screen>
      <Menu />

      <Container>
        <Header text="Meus pedidos" />

        <Center>

          {!kits && (
            <Empty
              icon={<DocumentCopy size="60" variant="Bulk" />}
              title="Sem Kits"
              text="Crie agora mesmo seus kits e agilize o seu trabalho"
              to="/kits"
              link="Criar um kit agora mesmo"
            />
          )}

          {kits && kits.length <= 0 && (
            <Empty
              icon={<DocumentCopy size="60" variant="Bulk" />}
              title="Sem Kits"
              text="Crie agora mesmo seus kits e agilize o seu trabalho"
              to="/kits"
              link="Criar um kit agora mesmo"
            />
          )}

          {kits && kits.length > 0 && (
            <>

              <Link to="/kits">
                <Add size="24" />
                Criar Kit
              </Link>

              <Filter />

              <TableList>
                <thead>
                  <tr>
                    <th>Nome do kit</th>
                    <th>Documentos</th>
                    <th>#</th>
                  </tr>
                </thead>

                <tbody>
                  {kits.map(({
                    id, nameKit, request,
                  }) => (
                    <tr>
                      <td>{nameKit}</td>
                      <td>{request.length}</td>

                      <td>
                        <button type="button" className="success" onClick={() => handleEditKit({ request })}><Edit size="20px" /></button>
                        <button type="button" className="red" onClick={() => handleDeleteKit(id)}><Trash size="20px" /></button>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </TableList>
            </>
          )}
        </Center>

        <Footer />
      </Container>
    </Screen>
  );
}
