import { useState } from 'react';
import { Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'sonner';
import * as styles from './styles';
import Start from '../../components/Start';
import ModalInput from '../../components/ModalInput';
import { H4, Span, P } from '../../assets/styles/Text';
import Input from '../../assets/styles/Input';
import Button from '../../assets/styles/Button';
import { auth, db } from '../../service/firebaseConnection';
import useUser from '../../store/User';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { storageUser } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (email === '') {
        toast.error('O email está vazio!');
        throw new Error('O email está vazio!');
      }

      if (password === '') {
        toast.error('A senha está vazia!');
        throw new Error('A senha está vazia!');
      }

      await signInWithEmailAndPassword(auth, email, password)
        .then(async (value) => {
          const { uid } = value.user;

          const docRef = doc(db, 'users', uid);
          const userProfile = await getDoc(docRef);

          const data = {
            uid,
            email: value.user.email,
            name: userProfile.data().name,
          };

          storageUser(data);
        })
        .catch((erro) => {
          throw Error(erro.message);
        });
    } catch (error) {
      const result = error.message.split(/\(([^)]+)\)/, 2);
      const resultMsg = result[1];

      switch (resultMsg) {
        case 'auth/invalid-email':
          toast.error('Digite um email válido');
          break;

        case 'auth/user-not-found':
          toast.error('Conta não encontrada');
          break;

        case 'auth/invalid-credential':
          toast.error('Revise os dados de login');
          break;

        case 'auth/wrong-password':
          toast.error('Senha incorreta');
          break;

        default:
          break;
      }
    } finally {
      // setLoad(false);
    }
  };

  return (
    <styles.Group>
      <Start />
      <styles.Container>

        <styles.TextTop>
          <H4 bold color={({ theme }) => theme.colors.primary.main}>Bem vindo a plataforma JK</H4>
          <Span>Certidões, Imóveis e Construções</Span>
          <div />

          <P bold color={({ theme }) => theme.colors.primary.main}>Acessar conta</P>
        </styles.TextTop>

        <styles.Form onSubmit={handleSubmit}>
          <ModalInput name="Email">
            <Input placeholder="Digite seu email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </ModalInput>

          <ModalInput name="Senha">
            <Input placeholder="Digite sua senha" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </ModalInput>

          <div className="div_checkbox">
            <Span>
              <input type="checkbox" />
              Manter-me conectado(a)
            </Span>

            <Link to="/">Esqueceu a senha?</Link>
          </div>

          <Button action={email && password} disabled={!email && !password}>Acessar</Button>
        </styles.Form>

        <styles.GroupLinks>
          <Span>Não tem uma conta?</Span>
          <Link to="/register">Crie uma conta</Link>
        </styles.GroupLinks>
      </styles.Container>
    </styles.Group>
  );
}
