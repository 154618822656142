import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Title from '../../components/Title';
import { Center, Container, Screen } from '../Home/styles';

export default function Help() {
  return (
    <Screen>
      <Menu />

      <Container>
        <Header text="Ajuda" />

        <Center>

            <Title
              title="Informações sobre o sistema"
              text="Conte com tutoriais e informações sobre o sistema"
            />

        </Center>

        <Footer />
      </Container>
    </Screen>
  );
}
