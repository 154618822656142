import styled from 'styled-components';

export const Screen = styled.article`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Center = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 32px 5%;
`;

export const GroupSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 36px;
`;

export const SectionSepare = styled.div`
  width: 47%;
  max-width: 700px;
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const Modal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px 20px;
  border-radius: 8px;

  .list-info{
    margin-top: 16px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.secondary.dark};
    display: flex;
    align-items: center;
    gap: 20px;

    > img{
      background-color: ${({ theme }) => theme.colors.secondary.dark};
      width: 44px;
      height: 44px;
      padding: 8px;
      border-radius: 100%;
    }

    div{
      display: flex;
      flex-direction: column;

      h5{
        font-size: 20px;
        font-weight: 600;
      }

      span{
        font-size: 16px;

        b{
          font-weight: 600;
        }
      }
    }

    a{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      padding: 8px;
      background-color: ${({ theme }) => theme.colors.outhers.success};
      border-radius: 4px;
    }
  }

  > a{
    width: 100%;
    padding: 8px 20px;
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
    font-size: 16px;
    border-radius: 8px;
    text-align: center;
    margin-top: 20px;
    color: ${({ theme }) => theme.colors.primary.main};

    &:hover{
      transition: 0.7s;
      background-color: ${({ theme }) => theme.colors.primary.main};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const GroupDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 28px;
`;
