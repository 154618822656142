import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { P, Span } from '../../assets/styles/Text';
import * as styles from './styles';

export default function ModalOrder({
  icon, title, text, link,
}) {
  return (
    <styles.Container>
      <Link to={link}>
        {icon}

        <P bold>{title}</P>
        <Span>{text}</Span>
      </Link>
    </styles.Container>
  );
}

ModalOrder.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
