import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAszPeAXSiQPgV9lhZHj8jiomjMkHlh49U',
  authDomain: 'jkcertidoes-9d94a.firebaseapp.com',
  projectId: 'jkcertidoes-9d94a',
  storageBucket: 'jkcertidoes-9d94a.firebasestorage.app',
  messagingSenderId: '780813497145',
  appId: '1:780813497145:web:31b0ed5eb1ba5cbc49aee8',
  measurementId: 'G-LB9T8DHVK4',
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export { db, auth };
