import PropTypes from 'prop-types';
import { Container } from './styles';

export default function FormGroup({
  children, error, name, info,
}) {
  return (
    <Container>
      {name && <label>{name}</label>}
      {children}
      {info}
      {error && <small>{error}</small>}
    </Container>
  );
}

FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  name: PropTypes.string,
  info: PropTypes.node,
};

FormGroup.defaultProps = {
  error: null,
  name: null,
  info: null,
};
