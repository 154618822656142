import {
  ArrowRight,
  Global, Home, Judge, Location, Notepad2, SearchNormal1, Star1, UserCirlceAdd,
} from 'iconsax-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { P, Span } from '../../assets/styles/Text';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Footer from '../../components/Footer';
import { Center, Container, Screen } from '../Home/styles';
import * as styles from './styles';
import useOrder from '../../store/Application';
import { db } from '../../service/firebaseConnection';

export default function SelectOrder() {
  const { orders, setOrders } = useOrder();
  const navigate = useNavigate();

  const [selectedPerson, setSelectedPerson] = useState(1);
  const [options, setOptions] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [ref, setRef] = useState('all');

  useEffect(() => {
    console.log(orders);
    console.log(orders.users);
    console.log(orders.users.map((user) => user.cpf));
  }, [orders]);

  const optionsLocal = [
    {
      name1: 'Todos',
      icon1: <Global size="20" />,
      ref1: 'all',

      name2: 'Registro Civil',
      icon2: <UserCirlceAdd size="20" />,
      ref2: 'civil',
    },
    {
      name1: 'Registro de Imóveis',
      icon1: <Home size="20" />,
      ref1: 'imoveis',

      name2: 'Notas',
      icon2: <Notepad2 size="20" />,
      ref2: 'notas',
    },
    {
      name1: 'Protesto',
      icon1: <Judge size="20" />,
      ref1: 'protesto',

      name2: 'Federais',
      icon2: <Location size="20" />,
      ref2: 'federais',
    },
    {
      name1: 'Estaduais',
      icon1: <Location size="20" />,
      ref1: 'estaduais',

      name2: 'Municipais',
      icon2: <Location size="20" />,
      ref2: 'municipais',
    },
  ];

  useEffect(() => {
    const listSolicitation = async () => {
      try {
        const solicitationRef = collection(db, 'solicitation');
        const querySnapshot = await getDocs(solicitationRef);

        if (!querySnapshot.empty) {
          const solicitationList = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setOptions(solicitationList);
        }
      } catch (error) {
        toast.error('Erro ao buscar requisições');
      }
    };

    listSolicitation();
  }, []);

  const handlePersonSelect = (userId) => {
    setSelectedPerson(userId);
  };

  const handleSelectOption = (option) => {
    const newOrders = { ...orders };
    const user = newOrders.users[selectedPerson - 1];

    console.log(user);

    if (!user.request) {
      user.request = [];
    }

    // Adicionar ou remover a seleção da opção
    const existingRequestIndex = user.request.findIndex((req) => req.name === option.name);
    if (existingRequestIndex >= 0) {
      user.request.splice(existingRequestIndex, 1); // Remover a opção se já estiver selecionada
    } else {
      user.request.push(option); // Adicionar a nova opção
    }

    // Atualizar o estado do orders
    setOrders(newOrders);
  };

  const handleNext = () => {
    const hasEmptyRequests = orders.users.some((user) => !user.request || user.request.length === 0);

    if (hasEmptyRequests) {
      toast.error('Por favor, preencha todas as solicitações antes de continuar.');
      return;
    }

    const formatarDataAtual = () => {
      const dataAtual = new Date(); // Pega a data atual

      const dia = String(dataAtual.getDate()).padStart(2, '0'); // Pega o dia e adiciona um zero à esquerda, se necessário
      const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Pega o mês (adiciona 1 porque começa do 0)
      const ano = dataAtual.getFullYear(); // Pega o ano

      return `${dia}/${mes}/${ano}`; // Retorna no formato DD/MM/AAAA
    };

    const orderWithDate = { ...orders, date: formatarDataAtual() };

    const storage = JSON.parse(localStorage.getItem('@order-cart')) || [];

    const updatedStorage = Array.isArray(storage) ? storage : [storage];

    updatedStorage.push(orderWithDate);

    localStorage.setItem('@order-cart', JSON.stringify(updatedStorage));

    navigate('/cart');
  };

  const listOptions = (element) => {
    const listIcon = (search) => {
      switch (search) {
        case 'civil':
          return <UserCirlceAdd size="24" />;

        case 'imoveis':
          return <Home size="24" />;

        case 'notas':
          return <Notepad2 size="24" />;

        case 'protesto':
          return <Judge size="24" />;

        default:
          return <Location size="24" />;
      }
    };

    if (ref === element.option || ref === 'all') {
      if (element.name.toLowerCase().includes(searchInput.toLowerCase())) {
        return (
          <styles.SearchOption
            key={element.id}
            onClick={() => handleSelectOption(element)}
            select={orders.users[selectedPerson - 1]?.request?.some((req) => req.name === element.name)}
          >
            <div>
              {listIcon(element.option)}
              <Span>{element.name}</Span>
            </div>
            <Star1 size="24" />
          </styles.SearchOption>
        );
      }
    }
    return null;
  };

  return (
    <Screen>
      <Menu />

      <Container>
        <Header text="Selecione os dados" />

        <Center>
          <styles.Button type="button">
            <Span bold>Continuar</Span>
            <ArrowRight />
          </styles.Button>

          <styles.ContainerSeparet>
            <styles.ContainerSelect>
              <P bold>Nome do pedido</P>

              <styles.GroupSelect>

                {orders && orders.users && orders.users.length > 0 && orders.users.map((user, index) => (
                  <styles.PeopleSelect
                    key={index}
                    onClick={() => handlePersonSelect(index + 1)}
                    select={selectedPerson === index + 1}
                  >
                    <li>
                      <Span>Nome:</Span>
                      <Span bold>{user.change === 1 ? user.name : user.nameCompany}</Span>
                    </li>
                    <li>
                      <Span>Documento:</Span>
                      <Span bold>{user.change === 1 ? user.cpf : user.cnpj}</Span>
                    </li>
                    <li>
                      <Span>Estado:</Span>
                      <Span bold>{user.state}</Span>
                    </li>
                    <li>
                      <Span>Cidade:</Span>
                      <Span bold>{user.city}</Span>
                    </li>
                  </styles.PeopleSelect>
                ))}

              </styles.GroupSelect>
            </styles.ContainerSelect>

            {selectedPerson && (
              <styles.ContainerOrder>
                <Span>Selecione qual documento deseja</Span>

                <styles.OrderOptions>

                  {optionsLocal.map((element) => (
                    <styles.GroupOptions>
                      <styles.Options
                        onClick={() => setRef(element.ref1)}
                        select={ref === element.ref1}
                      >
                        {element.icon1}
                        <Span>{element.name1}</Span>
                      </styles.Options>

                      <styles.Options
                        onClick={() => setRef(element.ref2)}
                        select={ref === element.ref2}
                      >
                        {element.icon2}
                        <Span>{element.name2}</Span>
                      </styles.Options>
                    </styles.GroupOptions>
                  ))}

                </styles.OrderOptions>

                <styles.ContainerSearch>
                  <styles.SearchInput>
                    <input
                      type="text"
                      placeholder="Pesquise qual certidão precisa"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <button type="button">
                      <SearchNormal1 size={24} />
                    </button>
                  </styles.SearchInput>

                  <styles.GroupSearch>

                      {options.map((element) => (
                        listOptions(element)
                      ))}

                  </styles.GroupSearch>

                </styles.ContainerSearch>
              </styles.ContainerOrder>
            )}
          </styles.ContainerSeparet>

          <styles.Button type="button" onClick={handleNext}>
            <Span bold>Continuar</Span>
            <ArrowRight />
          </styles.Button>
        </Center>
        <Footer />
      </Container>
    </Screen>
  );
}
