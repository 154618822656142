import styled, { css } from 'styled-components';

export default styled.button`
    border: 1px solid ${({ theme }) => theme.colors.primary.dark};
    text-align: center;
    padding: 8px 120px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.primary.main};

    font-size: 20px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;

    &:hover{
      transition: 0.7s;
      background-color: ${({ theme }) => theme.colors.primary.dark};
    }

    ${({ action }) => !action && css`
      background-color: ${({ theme }) => theme.colors.primary.lighest};
      cursor: not-allowed;

      &:hover{
        background-color: ${({ theme }) => theme.colors.primary.lighest};
      }
    `}
`;
