import {
  Document, DocumentCopy,
} from 'iconsax-react';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import ModalOrder from '../../components/ModalOrder';
import Title from '../../components/Title';
import * as styles from '../Home/styles';
import Footer from '../../components/Footer';
// import * as styles from './styles';

export default function Order() {
  return (
    <styles.Screen>
      <Menu />

      <styles.Container>

        <Header text="Fazer pedidos" />

        <styles.Center>
          <Title
            title="Solicite sua certidão"
            text="Escolha o tipo de certidão, preencha as informações e acompanhe o processo em tempo real."
          />

          <styles.GroupDiv>
            <ModalOrder
              icon={<Document size={40} />}
              title="Solicitar um Documento"
              text="Busque documentos em milhares de instituições por todo o Brasil."
              link="/metaorder"
            />

            <ModalOrder
              icon={<DocumentCopy size={40} />}
              title="Solicitar um Kit"
              text="Crie kits personalizados com os documentos que você precisa."
              link="/mykits"
            />
          </styles.GroupDiv>

          {/* <styles.GroupDiv>
            <ModalOrder
              icon={<Scan size={40} />}
              title="Extração de Dados"
              text="Carregue seus documentos e extraia automaticamente os campos mais importantes."
              link="/"
            />

            <ModalOrder
              icon={<Briefcase size={40} />}
              title="Solicitar uma Diligência"
              text="Conte com correspondentes jurídicos em várias cidades para diligências em prefeituras."
              link="/"
            />

            <ModalOrder
              icon={<SearchNormal1 size={40} />}
              title="Fazer uma Pesquisa"
              text="Receba certidões e documentos em formato físico ou digital, de forma prática."
              link="/"
            />
          </styles.GroupDiv> */}
        </styles.Center>

        <Footer />
      </styles.Container>
    </styles.Screen>

  );
}
