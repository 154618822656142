import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as styles from './styles';
import Title from '../Title';

export default function Empty({
  icon, title, text, to, link,
}) {
  return (
    <styles.Container>
      {icon}

      <Title
        title={title}
        text={text}
      />

      <Link to={to}>{link}</Link>
    </styles.Container>
  );
}

Empty.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
