import styled, { css } from 'styled-components';

export const H1 = styled.h1`
  font-size: 40px;

  ${({ color }) => color && css`
    color: ${color};
  `}

  ${({ bold }) => bold && css`
    font-weight: 600;
  `}
`;

export const H2 = styled.h2`
  font-size: 32px;

  ${({ color }) => color && css`
    color: ${color};
  `}

  ${({ bold }) => bold && css`
    font-weight: 600;
  `}
`;

export const H3 = styled.h3`
  font-size: 28px;

  ${({ color }) => color && css`
    color: ${color};
  `}

  ${({ bold }) => bold && css`
    font-weight: 600;
  `}
`;

export const H4 = styled.h4`
  font-size: 24px;

  ${({ color }) => color && css`
    color: ${color};
  `}

  ${({ bold }) => bold && css`
    font-weight: 600;
  `}
`;

export const P = styled.p`
  font-size: 20px;

  ${({ color }) => color && css`
    color: ${color};
  `}

  ${({ bold }) => bold && css`
    font-weight: 600;
  `}
`;

export const Span = styled.span`
  font-size: 16px;

  ${({ color }) => color && css`
    color: ${color};
  `}

  ${({ bold }) => bold && css`
    font-weight: 600;
  `}
`;

// Rubik

export const RubikH1 = styled.h1`
  font-size: 52px;

  ${({ color }) => color && css`
    color: ${color};
  `}
`;

export const RubikH2 = styled.h2`
  font-size: 40px;

  ${({ color }) => color && css`
    color: ${color};
  `}
`;
