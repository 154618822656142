import { Link } from 'react-router-dom';
import { DocumentCopy, Edit2 } from 'iconsax-react';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Title from '../../components/Title';
import { P, Span } from '../../assets/styles/Text';
import * as styles from './styles';
import Footer from '../../components/Footer';

export default function Home() {
  return (
    <styles.Screen>
      <Menu />

      <styles.Container>
        <Header text="Painel" />

        <styles.Center>
          <Title
            title="Bem-vindo(a), Usuário!"
            text="Tenha tudo o que você precisa ao seu alcance"
          />

          <styles.GroupSection>
            <styles.SectionSepare>
              <styles.Modal>
                <P bold>Meus pedidos</P>
                <Span>Veja o gráficos dos seus pedidos</Span>

                <div className="list-info">
                  <DocumentCopy />

                  <div>
                    <h5>Nome do kit</h5>
                    <span>
                      Documentos:
                      <b>10</b>
                    </span>

                    <span>
                      Valor
                      <b>R$ 140,00</b>
                    </span>
                  </div>

                  <Link to="/">
                    <Edit2 />
                  </Link>
                </div>

                <Link to="/">Ver mais</Link>
              </styles.Modal>

              <styles.Modal>
                <P bold>Suas finanças</P>
                <Span>Veja o gráfico das suas finanças </Span>

                <p>Sem finanças</p>

                <Link to="/">Ver mais</Link>
              </styles.Modal>

              <styles.Modal>
                <P bold>Configurações</P>
                <Span>Configure o seu perfil de acordo com sua preferencia</Span>

                <Link to="/">Ver mais</Link>
              </styles.Modal>
            </styles.SectionSepare>

            <styles.SectionSepare>
              <styles.Modal>
                <P bold>Meus pedidos</P>
                <Span>Veja o gráficos dos seus pedidos</Span>

                <div className="list-info">
                  <DocumentCopy size="20" />

                  <div>
                    <h5>Nome do kit</h5>
                    <span>
                      Documentos:
                      <b>10</b>
                    </span>

                    <span>
                      Valor
                      <b>R$ 140,00</b>
                    </span>
                  </div>

                  <Link to="/">
                    <Edit2 />
                  </Link>
                </div>

                <Link to="/">Ver mais</Link>
              </styles.Modal>

              <styles.Modal>
                <P bold>Tutoriais</P>
                <Span>Veja alguns vídeos sobre a plataforma</Span>

                <div className="list-info">
                  <DocumentCopy size="20" />

                  <div>
                    <h5>Nome do kit</h5>
                    <span>
                      Documentos:
                      <b>10</b>
                    </span>

                    <span>
                      Valor
                      <b>R$ 140,00</b>
                    </span>
                  </div>

                  <Link to="/">
                    <Edit2 size="20" />
                  </Link>
                </div>

                <Link to="/">Ver mais</Link>
              </styles.Modal>

            </styles.SectionSepare>
          </styles.GroupSection>
        </styles.Center>

        <Footer />
      </styles.Container>
    </styles.Screen>
  );
}
