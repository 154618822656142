import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'sonner';
import GlobalStyles from '../../assets/styles/global';
import defaultTheme from '../../assets/styles/themes/default';
import RoutesComp from '../../routes';

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <Toaster richColors position="top-right" expand={false} />
      <BrowserRouter>
        <RoutesComp />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
