import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const ButtonFilter = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px 16px;
  border-radius: 4px;
  border: 0;

  svg{
    stroke: ${({ theme }) => theme.colors.black};
  }
`;
