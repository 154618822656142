import { Route, Routes } from 'react-router-dom';
import RouteWrapper from './route';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Register from '../pages/Register';
import Order from '../pages/Order';
import MetaOrder from '../pages/MetaOrder';
import SelectOrder from '../pages/SelectOrder';
import Cart from '../pages/Cart';
import MyOrder from '../pages/MyOrder';
import MyKits from '../pages/MyKits';
import Kits from '../pages/Kits';
import Finance from '../pages/Finance';
import Setting from '../pages/Setting';
import Help from '../pages/Help';
import KitOrder from '../pages/KitOrder';

export default function RoutesComp() {
  return (
    <Routes>
      <Route path="/" element={<RouteWrapper loggedComponent={<Home />} defaultComponent={<Register />} IsPrivate Access />} />
      <Route path="/login" element={<RouteWrapper loggedComponent={<Home />} defaultComponent={<Login />} />} />
      <Route path="/register" element={<RouteWrapper loggedComponent={<Home />} defaultComponent={<Register />} />} />
      <Route path="/order" element={<RouteWrapper loggedComponent={<Order />} defaultComponent={<Login />} IsPrivate />} />
      <Route path="/metaorder" element={<RouteWrapper loggedComponent={<MetaOrder />} defaultComponent={<Login />} IsPrivate />} />
      <Route path="/selectorder" element={<RouteWrapper loggedComponent={<SelectOrder />} defaultComponent={<Login />} IsPrivate />} />
      <Route path="/myorder" element={<RouteWrapper loggedComponent={<MyOrder />} defaultComponent={<Login />} IsPrivate />} />
      <Route path="/cart" element={<RouteWrapper loggedComponent={<Cart />} defaultComponent={<Login />} IsPrivate />} />
      <Route path="/kits" element={<RouteWrapper loggedComponent={<Kits />} defaultComponent={<Login />} IsPrivate />} />
      <Route path="/mykits" element={<RouteWrapper loggedComponent={<MyKits />} defaultComponent={<Login />} IsPrivate />} />
      <Route path="/finance" element={<RouteWrapper loggedComponent={<Finance />} defaultComponent={<Login />} IsPrivate />} />
      <Route path="/help" element={<RouteWrapper loggedComponent={<Help />} defaultComponent={<Login />} IsPrivate />} />
      <Route path="/setting" element={<RouteWrapper loggedComponent={<Setting />} defaultComponent={<Login />} IsPrivate />} />
      <Route path="/kitorder" element={<RouteWrapper loggedComponent={<KitOrder />} defaultComponent={<Login />} IsPrivate />} />
    </Routes>
  );
}
