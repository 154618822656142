export default {
  colors: {
    black: '#000000',
    white: '#ffffff',
    primary: {
      lighest: '#ffb1b1',
      light: '#ff4c4c',
      main: '#e60000',
      dark: '#810000',
      darkest: '#500000',
    },
    secondary: {
      lighest: '#fef1ae',
      light: '#fbdf4e',
      main: '#fad61e',
      dark: '#e1bc05',
      darkest: '#af9200',
    },
    contrast: {
      lighest: '#dcd8d9',
      light: '#a6a6a6',
      main: '#737373',
      dark: '#404040',
      darkest: '#282626',
    },
    outhers: {
      gold: '#fad415',
      background: '#efefefef',
      list: '#f7f6fe',
      dark: '#262626',
      success: '#48d904',
      danger: '#ff002f',
      gradient: '#ff4c4c, linear-gradient(90deg, #ff4c4c 0%, #810000 100%)',
    },
  },
};
