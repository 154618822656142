import { Link } from 'react-router-dom';
import {
  DocumentCopy, DollarSquare, Edit2, Folder, Home, LogoutCurve,
  MessageQuestion,
  Setting2,
} from 'iconsax-react';
import * as styles from './styles';
import Logo from '../../assets/imgs/logo.svg';
import useUser from '../../store/User';

export default function Menu() {
  const listMenu = [
    { icon: <Home size="24" />, name: 'Painel', link: '/' },
    { icon: <Folder size="24" />, name: 'Meus pedidos', link: '/myorder' },
    { icon: <DocumentCopy size="24" />, name: 'Meus kits', link: '/mykits' },
    { icon: <DollarSquare size="24" />, name: 'Financeiro', link: '/finance' },
    { icon: <MessageQuestion size="24" />, name: 'Ajuda', link: '/help' },
    { icon: <Setting2 size="24" />, name: 'Configurações', link: '/setting' },
  ];

  const { logoutUser } = useUser();

  return (
    <styles.Container>
      <img src={Logo} alt="logo" />

      <Link to="/order">
        <Edit2 size="24" />
        Fazer pedido
      </Link>

      <styles.UlList>
        {listMenu.map((element) => (
          <li>
            <Link to={element.link}>
              {element.icon}
              {element.name}
            </Link>
          </li>
        ))}
      </styles.UlList>

      <styles.Exit type="button" onClick={logoutUser}>
        <LogoutCurve size="32" />
        Sair
      </styles.Exit>
    </styles.Container>
  );
}
