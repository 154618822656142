import styled from 'styled-components';

export const GroupStatus = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StatusFilter = styled.div`
  width: 100%;
  max-width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px 20px;
  border-radius: 8px;

  .yellow{
    color: ${({ theme }) => theme.colors.secondary.dark};
  }

  .green{
    color: ${({ theme }) => theme.colors.outhers.success};
  }

  .red{
    color: ${({ theme }) => theme.colors.outhers.danger};
  }
`;
