import styled from 'styled-components';

export const Group = styled.article`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55%;
`;

export const TextTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div{
    width: 50%;
    height: 2px;
    margin: 24px 0;
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

export const OnChangeUser = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 28px;


  :nth-child(${({ change }) => change}){
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
  }

  button{
    background-color: transparent;
    border: 0;
    text-align: center;
    padding: 8px 12px;
    border-radius: 4px 4px 0px 0px;
    font-size: 16px;
  }
`;

export const Form = styled.form`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 56px;
  gap: 28px;

  .div_checkbox{
    width: 100%;
    /* display: flex; */
    display: none;
    flex-direction: row;
    justify-content: space-between;

    input{
      width: 16px;
      height: 16px;
      border: 1px solid ${({ theme }) => theme.colors.contrast.dark};
      cursor: pointer;
      margin-right: 4px;
      padding: 16px;
    }

    span{
      display: flex;
      align-items: center;
    }

    a{
      font-size: 16px;
      font-weight: 300;
      color: ${({ theme }) => theme.colors.primary.main};
      text-decoration-line: underline;
      text-decoration-style: solid;
    }
  }
`;

export const GroupInput = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 28px;
`;

export const GroupLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 48px;

  a{
    font-size: 16px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration-line: underline;
    text-decoration-style: solid;
  }
`;
