import styled from 'styled-components';

export const ContainerSeparet = styled.article`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 32px;
`;

export const Forms = styled.form`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Orders = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;

  > button{
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.primary.main};
    font-size: 16px;
    padding: 8px;
    border-radius: 4px;
    background-color: transparent;

    &:hover{
      transition: 0.7s;
      background-color: ${({ theme }) => theme.colors.primary.main};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Order = styled.div`
  width: 100%;
  padding: 20px 16px;
  border-radius: 8px;
  display: flex;
  min-height: 230px;
  flex-direction: column;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const OrderToggle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    gap: 16px;
  }

  button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border: 0;
    background-color: transparent;

    svg{
      color: ${({ theme }) => theme.colors.primary.main};
    }

    &:last-child{
      svg{
        color: ${({ theme }) => theme.colors.black};
      }
    }

  }
`;

export const OnChangeUser = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;


  :nth-child(${({ change }) => change}){
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
  }

  button{
    width: 100%;
    max-width: 100px;
    background-color: transparent;
    border: 0;
    text-align: center;
    text-transform: uppercase;
    padding: 8px 0;
    border-radius: 4px 4px 0px 0px;
    font-size: 16px;
  }

`;

export const GroupInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .group-row{
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
`;

export const OrderList = styled.section`
  width: 40%;
  height: fit-content;
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.white};

  button{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    gap: 8px;
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-weight: 600;
    border: 0;

    &:hover{
      transition: 0.7s;
      background-color: ${({ theme }) => theme.colors.primary.dark};
    }
  }
`;

export const OrderListContent = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 12px;
  border-radius: 4px;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.outhers.background};

  li{
    font-size: 16px;

    b{
      font-weight: 600;
    }
  }
`;
