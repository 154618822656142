import { Flash, Lock1 } from 'iconsax-react';
import * as styles from './styles';
import { H4, P } from '../../assets/styles/Text';

export default function Start() {
  return (
    <styles.Container>
      <div>
        <H4 bold>
          Acesse sua conta e simplifique sua gestão de certidões
        </H4>
        <P>
          Com poucos cliques, encontre, emita e gerencie todas as certidões que sua empresa precisa, de forma rápida e descomplicada.
        </P>
      </div>

      <div>
        <small>
          <Lock1 size="64" />
          <span>
            <b>Segurança garantida:</b>
            {' '}
            Seus dados protegidos com a mais alta tecnologia.
          </span>
        </small>

        <small>
          <Flash size="64" />
          <span>
            <b>Agilidade e praticidade:</b>
            {' '}
            Tudo centralizado para facilitar seu dia a dia.
          </span>
        </small>
      </div>
    </styles.Container>
  );
}
