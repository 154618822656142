import {
  Bag2, InfoCircle, Trash,
} from 'iconsax-react';
import { useEffect, useState } from 'react';
import { addDoc, collection, getDocs } from 'firebase/firestore';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Title from '../../components/Title';
import Footer from '../../components/Footer';
import * as styles from './styles';
import { Center, Container, Screen } from '../Home/styles';
import { P } from '../../assets/styles/Text';
import Empty from '../../components/Empty';
import useUser from '../../store/User';
import { db } from '../../service/firebaseConnection';
// import useOrder from '../../store/Application';

export default function Cart() {
  const { user } = useUser();
  const navigate = useNavigate();

  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('@order-cart'));

    console.log(data);
    console.log(user);

    setApplications(data);
  }, [localStorage.getItem('@order-cart')]);

  const handleDelete = (index) => {
    const updatedApplications = applications.filter((_, i) => i !== index);
    setApplications(updatedApplications);
    localStorage.setItem('@order-cart', JSON.stringify(updatedApplications));
  };

  const calculateOrderPrice = (order) => order.users.reduce((total, us) => {
    const userTotal = (us.request || []).reduce((usTotal, request) => usTotal + (request.prince || 0), 0);
    return total + userTotal;
  }, 0);

  const clearOrdersAndCookies = () => {
    // Limpar pedidos no localStorage
    localStorage.removeItem('@order-cart');
    setApplications([]);
  };

  const SendApplications = async () => {
    try {
      const applicationsRef = collection(db, 'applications');

      // Buscar aplicações existentes para determinar o próximo número de pedido
      const querySnapshot = await getDocs(applicationsRef);
      const existingApplications = querySnapshot.docs.map((doc) => doc.data());
      const nextOrderNumber = existingApplications.length + 1;

      for (let i = 0; i < applications.length; i += 1) {
        const application = applications[i];

        application.orderNumber = nextOrderNumber + i;
        application.status = 'pending';
        application.userName = user.name;
        application.userUid = user.uid;
        console.log(application);
        application.value = calculateOrderPrice(application);

        // Adicionar o pedido ao Firestore
        await addDoc(applicationsRef, application);
      }

      toast.success('Pedido realizado com sucesso');

      clearOrdersAndCookies();
      navigate('/myorder');
    } catch (error) {
      console.log(error);
      toast.error('Erro ao fazer pedido');
    }
  };

  return (
    <Screen>
      <Menu />

      <Container>

        <Header text="Pedidos aguardando pagamento" />

        <Center>

          {!applications && (
              <Empty
                icon={<Bag2 size="60" variant="Bulk" />}
                title="Sem pedidos"
                text="Realize um pedido, entre e selecione uma das opções e efetue seu pedido"
                to="/order"
                link="Realize um pedido agora mesmo"
              />
          )}

          {applications && applications.length <= 0 && (
            <Empty
              icon={<Bag2 size="60" variant="Bulk" />}
              title="Sem pedidos"
              text="Realize um pedido, entre e selecione uma das opções e efetue seu pedido"
              to="/order"
              link="Realize um pedido agora mesmo"
            />
          )}

          {applications && applications.length > 0 && (
            <>
              <Title
                title="Pedidos pendentes"
                text="Para prosseguir com os pedidos efetue o pagamento"
              />

              <styles.TableList>
                <thead>
                  <tr>
                    <th>Nome do pedido</th>
                    <th>Data</th>
                    <th>Valor</th>
                    <th>Solicitações</th>
                    <th>#</th>
                  </tr>
                </thead>

                <tbody>
                  {applications.map(({
                    nameOrder, date, users,
                  }, index) => (
                    <tr>
                      <td>{nameOrder}</td>
                      <td>{date}</td>
                      <td>
                        R$
                        {' '}
                        {calculateOrderPrice({ users })}
                      </td>
                      <td>{users.length}</td>
                      <td>
                        {/* <button type="button" className="yellow" onClick={() => handleEdit(id)}><Edit2 size="20px" /></button> */}
                        <button type="button" className="grey"><InfoCircle size="20px" /></button>
                        <button type="button" className="red" onClick={() => handleDelete(index)}><Trash size="20px" /></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </styles.TableList>
            </>
          )}

        </Center>

        {applications && (
          <styles.GroupBottom>
            <styles.BuyCart>
              <P>
                Pedidos totais:
                <b>{applications.length}</b>
              </P>

              <P>
                Valor total:
                <b>
                  R$
                  {applications.reduce((total, application) => total + calculateOrderPrice(application), 0)}
                </b>
              </P>

              <button type="button" onClick={SendApplications}>
                Finalizar
              </button>
            </styles.BuyCart>

            <Footer />
          </styles.GroupBottom>
        )}

      </Container>
    </Screen>
  );
}
