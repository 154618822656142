import PropTypes from 'prop-types';
import * as styles from './styles';
import { H4, P } from '../../assets/styles/Text';

export default function Title({ title, text }) {
  return (
    <styles.Container>
      <H4 bold>{title}</H4>
      <P>{text}</P>
    </styles.Container>
  );
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
