import { Copyright } from 'iconsax-react';
import * as styles from './styles';
import { Span } from '../../assets/styles/Text';

export default function Footer() {
  return (
    <styles.Container>
      <div className="top">
        <Span>
          <Copyright size="20" />
          {' '}
          2024 JK Negócios. Todos os direitos reservados.
        </Span>
      </div>

      <div className="bottom">
        <span>Desenvolvido por</span>
        <a href="/#" target="_blank">Fabricio Cipriano</a>
      </div>
    </styles.Container>
  );
}
