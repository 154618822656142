import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;

  .top{
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: ${({ theme }) => theme.colors.contrast.light};

    svg{
      margin-bottom: -3px;
      stroke: ${({ theme }) => theme.colors.black};
    }
  }

  .bottom{
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.contrast.main};

    a{
      color: ${({ theme }) => theme.colors.white};
      text-decoration: underline;
    }
  }
`;
