import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import {
  collection, doc, getDocs, query, setDoc, where,
} from 'firebase/firestore';
import { ShieldSecurity } from 'iconsax-react';
import { toast } from 'sonner';
import { auth, db } from '../../service/firebaseConnection';
import * as styles from '../Login/styles';
import Start from '../../components/Start';
import ModalInput from '../../components/ModalInput';
import { H4, Span, P } from '../../assets/styles/Text';
import Input from '../../assets/styles/Input';
import Button from '../../assets/styles/Button';
import useUser from '../../store/User';

export default function Register() {
  const navigate = useNavigate();

  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [tel, setTel] = useState('');
  const [name, setName] = useState('');
  const [nameCompany, setNameCompany] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [change, setChange] = useState(1);
  // const [warn, setWarn] = useState(false);
  // const [warnText, setWarnText] = useState('');

  const { storageUser } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // setLoad(true);

      if (cpf === '' && change) {
        // setWarn(true);
        toast.error('O seu CPF está vazio!');
        throw new Error('O seu cpf está vazio!');
      }

      if (cnpj === '' && !change) {
        toast.error('O seu CNPJ está vazio!');
        throw new Error('O seu cnpj está vazio!');
      }

      if (tel === '') {
        toast.error('O seu telefone está vazio!');
        throw new Error('O seu telefone está vazio!');
      }

      if (name === '' && change) {
        toast.error('O seu nome está vazio!');
        throw new Error('O seu nome está vazio!');
      }

      if (nameCompany === '' && !change) {
        toast.error('O nome da sua empresa está vazio!');
        throw new Error('O nome da sua empresa está vazio!');
      }

      if (email === '') {
        toast.error('O email está vazio!');
        throw new Error('O email está vazio!');
      }

      if (password === '') {
        toast.error('A senha está vazia!');
        throw new Error('A senha está vazia!');
      }

      if (change === 1) {
        const q = query(collection(db, 'users'), where('cpf', '==', cpf));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          await createUserWithEmailAndPassword(auth, email, password)
            .then(async (value) => {
              const { uid } = value.user;

              await setDoc(doc(db, 'users', uid), {
                cpf,
                tel,
                name,
                email,
              })
                .then(() => {
                  const data = {
                    uid,
                    email: value.user.email,
                    name,
                  };

                  storageUser(data);

                  navigate('/');
                })
                .catch((error) => {
                  throw Error(error.message);
                });
            })
            .catch((error) => {
              throw Error(error.message);
            });
        } else {
          toast.error('Este CPF já está cadastrado!');
          throw new Error('Este cpf já está cadastrado!');
        }
      } else {
        const q = query(collection(db, 'users'), where('cnpj', '==', cnpj));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          await createUserWithEmailAndPassword(auth, email, password)
            .then(async (value) => {
              const { uid } = value.user;

              await setDoc(doc(db, 'users', uid), {
                cnpj,
                access: false,
                tel,
                name: nameCompany,
                email,
              })
                .then(() => {
                  const data = {
                    uid,
                    email: value.user.email,
                    name: nameCompany,
                  };

                  storageUser(data);
                  navigate('/');
                })
                .catch((error) => {
                  throw Error(error.message);
                });
            })
            .catch((error) => {
              throw Error(error.message);
            });
        } else {
          toast.error('Este CNPJ já está cadastrado!');
          throw new Error('Este cnpj já está cadastrado!');
        }
      }
    } catch (error) {
      const result = error.message.split(/\(([^)]+)\)/, 2);

      const errorMsg = result[1];
      // setWarnText(error.message);

      switch (errorMsg) {
        case 'auth/weak-password':
          toast.error('A senha precisa ter no minímo 6 caracteres');
          break;

        case 'auth/email-already-in-use':
          toast.error('O email já está em uso');
          break;

        case 'auth/invalid-email':
          toast.error('Digite um email válido');
          break;

        default:
          break;
      }
    } finally {
      // setLoad(false);
    }
  };

  return (
    <styles.Group>
      <Start />
      <styles.Container>

        <styles.TextTop>
          <H4 bold color={({ theme }) => theme.colors.primary.main}>Bem vindo a plataforma JK</H4>
          <Span>Certidões, Imóveis e Construções</Span>
          <div />

          <P bold color={({ theme }) => theme.colors.primary.main}>Crie sua conta</P>
        </styles.TextTop>

        <styles.Form onSubmit={handleSubmit}>

          <styles.OnChangeUser change={change}>
            <button type="button" onClick={() => setChange(1)}>Pessoa Física</button>
            <button type="button" onClick={() => setChange(2)}>Pessoa Juridica</button>
          </styles.OnChangeUser>

          <styles.GroupInput>

            {change === 1 && (
              <ModalInput name="Cpf">
                <Input placeholder="Digite seu CPF" type="text" value={cpf} onChange={(e) => setCpf(e.target.value)} />
              </ModalInput>
            )}

            {change !== 1 && (
              <ModalInput name="Cnpj">
                <Input placeholder="Digite seu CNPJ" type="text" value={cnpj} onChange={(e) => setCnpj(e.target.value)} />
              </ModalInput>
            )}

            <ModalInput name="Telefone">
              <Input placeholder="Digite seu telefone" type="tel" value={tel} onChange={(e) => setTel(e.target.value)} />
            </ModalInput>
          </styles.GroupInput>

          {change === 1 && (
            <ModalInput name="Nome">
              <Input placeholder="Digite seu nome completo" type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </ModalInput>
          )}

          {change !== 1 && (
            <ModalInput name="Nome da empresa">
              <Input placeholder="Digite o nome da empresa" type="text" value={nameCompany} onChange={(e) => setNameCompany(e.target.value)} />
            </ModalInput>
          )}

          <ModalInput name="Email">
            <Input placeholder="Digite seu Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </ModalInput>

          <ModalInput
            name="Senha"
            info={(
              <span>
                <ShieldSecurity size="20" />
                A senha deve conter no minímo 6 caracteres
              </span>
)}
          >
            <Input placeholder="Digite sua senha" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </ModalInput>

          <Button
            action={email && password}
            disabled={!email && !password}
          >
            Criar conta
          </Button>

        </styles.Form>

        <styles.GroupLinks>
          <Span>Já tem uma conta?</Span>
          <Link to="/login">Faça Login</Link>
        </styles.GroupLinks>
      </styles.Container>
    </styles.Group>
  );
}
