import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg{
    color: ${({ theme }) => theme.colors.primary.dark};
  }

  a{
    width: 100%;
    text-align: center;
    padding: 8px;
    margin-top: 20px;
    font-size: 20px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.primary.main};

    &:hover{
      transition: 0.7s;
      background-color: ${({ theme }) => theme.colors.primary.main};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;
