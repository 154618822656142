import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Title from '../../components/Title';
import { Center, Container, Screen } from '../Home/styles';
import * as styles from './styles';

export default function Setting() {
  return (
    <Screen>
      <Menu />

      <Container>
        <Header text="Configuração" />

        <Center>
          <styles.Container>
            <Title
              title="Configure do seu jeito"
              text="Configure o seu perfil do seu jeito"
            />
          </styles.Container>
        </Center>

        <Footer />
      </Container>
    </Screen>
  );
}
