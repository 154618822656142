import { create } from 'zustand';

const useOrder = create((set) => ({
  orders: [],
  orderSelect: [],
  orderCart: [],
  personRequests: {},
  request: {},

  setOrderSelect: (order = []) => {
    set({ orderSelect: order });
  },

  setOrderCart: (order = []) => {
    set({ orderCart: order });
  },

  setPersonRequests: (requests = {}) => {
    set({ personRequests: requests });
  },

  setOrders: (orders = []) => {
    set({ orders });
  },

  setRequest: (request = {}) => {
    set({ request });
  },

  loadFromCookies: () => {
    const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
      const [name, value] = cookie.split('=');
      acc[name] = value;
      return acc;
    }, {});

    set({
      orders: JSON.parse(cookies.orders || '[]'),
      personRequests: JSON.parse(cookies.personRequests || '{}'),
    });
  },
}));

export default useOrder;
