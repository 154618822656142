import styled from 'styled-components';

export default styled.input`
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.contrast.lighest};
  border: 0;
  font-size: 16px;

  &::placeholder{
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.5;
  }
`;
