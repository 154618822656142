import { create } from 'zustand';
import { doc, getDoc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { auth, db } from '../service/firebaseConnection';

const useUser = create((set) => ({
  users: [],

  storageUser: (data) => {
    localStorage.setItem('@user-data', JSON.stringify(data));
    set({ storage: data });
  },

  setLoading: (boolean = false) => {
    set({ loading: boolean });
  },

  fetchUserData: async (userId) => {
    if (userId) {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);

      const data = {
        uid: userId,
        ...userDoc.data(),
      };

      let access = false;

      if (userDoc.data().hasOwnProperty('access')) {
        access = userDoc.data().access;
      } else {
        access = true;
      }

      set({ user: data, access });
    }
  },

  logoutUser: async () => {
    await signOut(auth);
    localStorage.removeItem('@user-data');
    localStorage.removeItem('@order-cart');
    set({ user: null, storage: null });
  },
}));

export default useUser;
