import styled from 'styled-components';

export const Container = styled.nav`
  max-width: 35%;
  width: 360px;
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 32px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.15);

  > img{
    width: 140px;
  }

  > a{
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 100%;
    padding: 12px 0px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.primary.main};

    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;

    svg{
      stroke: ${({ theme }) => theme.colors.white};
    }

    &:hover{
      transition: 0.7s;
      background-color: ${({ theme }) => theme.colors.primary.dark};
    }
  }
`;

export const UlList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  list-style-type: none;
  gap: 32px;

  li{
    width: 80%;

    svg{
      stroke: ${({ theme }) => theme.colors.black};
    }

    a{
      color: ${({ theme }) => theme.colors.black};
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      font-size: 20px;

      &:hover{
        transition: 0.7s;
        color: ${({ theme }) => theme.colors.primary.main};

        svg{
          transition: 0.7s;
          color: ${({ theme }) => theme.colors.primary.main};
        }
      }
    }
  }
`;

export const Exit = styled.button`
  margin-top: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  font-size: 20px;
  font-weight: 300;
  gap: 4px;

  svg{
    stroke: ${({ theme }) => theme.colors.black};
  }

  &:hover{
    transition: 0.7s;
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;
