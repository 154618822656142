import styled from 'styled-components';
import img from '../../assets/imgs/start.webp';

export const Container = styled.section`
  width: 45%;
  height: 100%;
  background-image: url(${img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  div{
    width: 100%;
    background: rgba(230, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 24px 12px;
    gap: 20px;
    color: ${({ theme }) => theme.colors.white};

    h4,p{
      width: 70%;
    }
  }

  svg{
    stroke: ${({ theme }) => theme.colors.white};
  }

  small{
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;

    img{
      width: 52px;
    }

    span{
      font-weight: 300;
      font-size: 24px;

      b{
        font-weight: 600;
      }
    }
  }
`;
