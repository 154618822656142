import styled from 'styled-components';

export const TableList = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 8px;
  overflow: hidden;
  border-collapse: collapse;
  table-layout: fixed;

  thead{
    background-color: ${({ theme }) => theme.colors.white};
    padding: 16px 12px;
    width: 100%;

    tr{
      width: 100%;
      display: flex;
      justify-content: space-between;

      th{
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  tbody{
    width: 100%;

    tr{
      width: 100%;
      padding: 16px 12px;
      display: flex;
      justify-content: space-between;
      gap: 12px;

      &:nth-child(2n + 1){
        background-color: ${({ theme }) => theme.colors.outhers.list} !important;
      }

      &:nth-child(1n + 2){
        background-color: ${({ theme }) => theme.colors.white};
      }

      td{
        width: 100%;
        gap: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 300;

        span{
          width: 80%;
          padding: 4px;
          text-align: center;
          border-radius: 4px;
        }

        .pending{
          color: ${({ theme }) => theme.colors.white};
          background-color: ${({ theme }) => theme.colors.outhers.danger};
        }

        .process{
          color: ${({ theme }) => theme.colors.white};
          background-color: ${({ theme }) => theme.colors.secondary.dark};
        }

        .success{
          color: ${({ theme }) => theme.colors.black};
          background-color: ${({ theme }) => theme.colors.outhers.success};
        }

        button{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          border: 0;
          border-radius: 4px;

          svg{
            stroke: ${({ theme }) => theme.colors.white};
          }
        }

        .yellow{
          background-color: ${({ theme }) => theme.colors.secondary.dark};
        }

        .grey{
          background-color: ${({ theme }) => theme.colors.contrast.dark};
        }

        .red{
          background-color: ${({ theme }) => theme.colors.outhers.danger};
          color: ${({ theme }) => theme.colors.white};
        }

        .green{
          background-color: ${({ theme }) => theme.colors.outhers.success};
        }
      }
    }
  }
`;

export const GroupBottom = styled.div`
  width: 100%;
  margin-top: auto;
`;

export const BuyCart = styled.div`
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  border-radius: 8px 8px 0 0;
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  padding: 48px 12%;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.white};

  b{
    font-weight: 600;
    margin-left: 4px;
  }

  button{
    width: 100%;
    max-width: 200px;
    border: 0;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.white};
    padding: 8px;
    margin-left: auto;
    font-size: 20px;
    font-weight: 600;

    &:hover{
      transition: 0.7s;
      background-color: ${({ theme }) => theme.colors.primary.dark};
    }
  }
`;
