import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Bag2, MessageQuestion, Notification } from 'iconsax-react';
import * as styles from './styles';
import { H4 } from '../../assets/styles/Text';

export default function Header({ text }) {
  return (
    <styles.Container>
      <H4 bold>{text}</H4>

      <styles.GroupIcons>
        <Link to="/help">
          <MessageQuestion size="28" />
        </Link>

        <button type="button">
          <Notification size="28" />
        </button>

        <Link to="/cart">
          <Bag2 size="28" />
        </Link>
      </styles.GroupIcons>
    </styles.Container>
  );
}

Header.propTypes = {
  text: PropTypes.string.isRequired,
};
