import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  padding: 20px 48px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: space-between;
  align-items: center;
`;

export const GroupIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  button{
    display: flex;
    background-color: transparent;
    border: 0;
  }

  svg{
    color: ${({ theme }) => theme.colors.black};

    &:hover{
      transition: 0.7s;
      color: ${({ theme }) => theme.colors.primary.main};
    }
  }
`;
