import { ArrowDown2, Filter as Filters } from 'iconsax-react';
import * as styles from './styles';
import { P } from '../../assets/styles/Text';

export default function Filter() {
  return (
    <styles.Container>
      <styles.ButtonFilter>
        <Filters size="20" />
        <P>Filtros</P>
        <ArrowDown2 size="20" />
      </styles.ButtonFilter>
    </styles.Container>
  );
}
