import styled from 'styled-components';
import ArrowD from '../imgs/arrow-down.svg';

export default styled.select`
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.contrast.lighest};
  border: 0;
  font-size: 16px;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(${ArrowD});
  background-position: right 10px top 50%;
  background-repeat: no-repeat;

  &:disabled{
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.5;
  }
`;
