import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label{
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 4px;
  }

  span{
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    margin-top: 12px;

    svg{
      stroke: ${({ theme }) => theme.colors.black};
    }
  }
`;
