import { ClipboardExport, DirectInbox, Folder } from 'iconsax-react';
import { useEffect, useState } from 'react';
import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import { H2, H4, Span } from '../../assets/styles/Text';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { Center, Container, Screen } from '../Home/styles';
import Footer from '../../components/Footer';
import * as styles from './styles';
import Filter from '../../components/Filter';
import { TableList } from '../Cart/styles';
import Empty from '../../components/Empty';
import { db } from '../../service/firebaseConnection';
import useUser from '../../store/User';

export default function MyOrder() {
  const { user } = useUser();
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const fetchApplicationsFromFirestore = async () => {
      try {
        const applicationsRef = collection(db, 'applications');
        const q = query(applicationsRef, where('userUid', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const applicationsList = querySnapshot.docs.map((doc) => doc.data());
        return setApplications(applicationsList);
      } catch (error) {
        console.error('Erro ao buscar dados do Firestore: ', error);
        return false; // Retorna um array vazio em caso de erro
      }
    };

    fetchApplicationsFromFirestore();
  }, []);

  const verifStatus = (status) => {
    switch (status) {
      case 'pending':
        return <Span className="pending">Pendente</Span>;

      case 'process':
        return <Span className="process">Processando</Span>;

      case 'success':
        return <Span className="success">Sucesso</Span>;

      default:
        return null;
    }
  };

  return (
    <Screen>
      <Menu />

      <Container>
        <Header text="Meus pedidos" />

        <Center>

          {!applications && (
            <Empty
              icon={<Folder size="60" variant="Bulk" />}
              title="Sem pedidos"
              text="Realize um pedido, entre e selecione uma das opções e efetue seu pedido"
              to="/order"
              link="Realize um pedido agora mesmo"
            />
          )}

          {applications && applications.length <= 0 && (
            <Empty
              icon={<Folder size="60" variant="Bulk" />}
              title="Sem pedidos"
              text="Realize um pedido, entre e selecione uma das opções e efetue seu pedido"
              to="/order"
              link="Realize um pedido agora mesmo"
            />
          )}

          {applications && applications.length > 0 && (
            <>
              <styles.GroupStatus>
                <styles.StatusFilter>
                  <H4 className="yellow">Processando</H4>
                  <H2>0</H2>
                </styles.StatusFilter>

                <styles.StatusFilter>
                  <H4 className="green">Finalizados</H4>
                  <H2>0</H2>
                </styles.StatusFilter>

                <styles.StatusFilter>
                  <H4 className="red">Pendentes</H4>
                  <H2>0</H2>
                </styles.StatusFilter>
              </styles.GroupStatus>

              <Filter />

              <TableList>
                <thead>
                  <tr>
                    <th>Nº</th>
                    <th>Nome do pedido</th>
                    <th>Data</th>
                    <th>Valor</th>
                    <th>Status</th>
                    <th>#</th>
                  </tr>
                </thead>

                <tbody>
                  {applications.map(({
                    orderNumber, nameOrder, date, value, status,
                  }) => (
                    <tr>
                      <td>{orderNumber}</td>
                      <td>{nameOrder}</td>
                      <td>{date}</td>
                      <td>
                        R$
                        {' '}
                        {value}
                      </td>
                      <td>{verifStatus(status)}</td>
                      <td>
                        <button type="button" className="yellow"><ClipboardExport size="20px" /></button>
                        <button type="button" className="grey"><DirectInbox size="20px" /></button>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </TableList>
            </>
          )}
        </Center>

        <Footer />
      </Container>
    </Screen>
  );
}
