import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Print = styled.div`
  width: 100%;
  max-width: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  margin-left: auto;
  padding: 16px 12px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black};

  b{
    font-size: 20px;
    font-weight: 600;
    margin-left: 8px;
  }

  button{
    width: 100%;
    padding: 4px 0;
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 4px;

    &:hover{
      transition: 0.7s;
      background-color: ${({ theme }) => theme.colors.primary.dark};
    }
  }
`;
