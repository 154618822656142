import {
  ArrowDown2, ArrowRight, Trash,
} from 'iconsax-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Input from '../../assets/styles/Input';
import { P, Span } from '../../assets/styles/Text';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import ModalInput from '../../components/ModalInput';
import Footer from '../../components/Footer';
import { Center, Container, Screen } from '../Home/styles';
import * as styles from '../MetaOrder/styles';
import Select from '../../assets/styles/Select';
import useOrder from '../../store/Application';

export default function KitOrder() {
  const navigate = useNavigate();
  const { request } = useOrder();

  const [nameOrder, setNameOrder] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState({});
  const [ordersLocal, setOrdersLocal] = useState([{
    change: 1,
    name: '',
    cpf: '',
    rg: '',
    cnpj: '',
    nameCompany: '',
    state: '',
    city: '',
    mother: '',
    father: '',
    birth: '',
  }]);

  useEffect(() => {
    const fetchStates = async () => {
      const apiURL = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados';

      axios.get(apiURL)
        .then((response) => {
          setStates(response.data);
        })
        .catch(() => {
          toast.error('Erro ao buscar estados');
        });
    };

    console.log(request);
    console.log(request.request);

    fetchStates();
  }, []);

  const handleStateChange = async (e, index) => {
    const stateSigla = e.target.value;
    const newOrders = [...ordersLocal];
    newOrders[index].state = stateSigla;
    newOrders[index].city = '';
    setOrdersLocal(newOrders);

    if (!cities[stateSigla]) {
      try {
        const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateSigla}/municipios`);
        setCities((prevCities) => ({
          ...prevCities,
          [stateSigla]: response.data,
        }));
      } catch (error) {
        toast.error('Erro ao buscar cidades');
      }
    }
  };

  const handleCityChange = (e, index) => {
    const newOrders = [...ordersLocal];
    newOrders[index].city = e.target.value;
    setOrdersLocal(newOrders);
  };

  const handleInputChange = (e, index, field) => {
    const newOrders = [...ordersLocal];
    newOrders[index][field] = e.target.value;
    setOrdersLocal(newOrders);
  };

  const handleChangeType = (index, type) => {
    const newOrders = [...ordersLocal];
    newOrders[index].change = type;

    // Clear inputs
    newOrders[index].name = '';
    newOrders[index].cpf = '';
    newOrders[index].rg = '';
    newOrders[index].cnpj = '';
    newOrders[index].nameCompany = '';
    newOrders[index].state = '';
    newOrders[index].city = '';
    newOrders[index].mother = '';
    newOrders[index].father = '';
    newOrders[index].birth = '';

    setOrdersLocal(newOrders);
  };

  const addNewOrder = () => {
    setOrdersLocal([...ordersLocal, {
      change: 1,
      name: '',
      cpf: '',
      rg: '',
      cnpj: '',
      nameCompany: '',
      state: '',
      city: '',
      mother: '',
      father: '',
      birth: '',
    }]);
  };

  const removeOrder = (index) => {
    const newOrders = ordersLocal.filter((_, i) => i !== index);
    setOrdersLocal(newOrders);
  };

  const handleSubmit = () => {
    const hasEmptyFields = ordersLocal.some((order) => (
      order.change === 1
        ? !order.cpf || !order.rg || !order.name || !order.state || !order.city
        : !order.cnpj || !order.nameCompany || !order.state || !order.city
    ));

    if (hasEmptyFields) {
      toast.error('Por favor, preencha todos os campos antes de enviar.');
      return;
    }

    const filteredOrders = ordersLocal.filter((order) => (
      order.change === 1
        ? order.cpf && order.rg && order.name && order.state && order.city
        : order.cnpj && order.nameCompany && order.state && order.city
    ));

    const formatarDataAtual = () => {
      const dataAtual = new Date(); // Pega a data atual

      const dia = String(dataAtual.getDate()).padStart(2, '0'); // Pega o dia e adiciona um zero à esquerda, se necessário
      const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Pega o mês (adiciona 1 porque começa do 0)
      const ano = dataAtual.getFullYear(); // Pega o ano

      return `${dia}/${mes}/${ano}`; // Retorna no formato DD/MM/AAAA
    };

    const ordersMeta = {
      nameOrder,
      date: formatarDataAtual(),
      users: filteredOrders.map((order) => ({
        ...order,
        cpf: order.cpf || undefined,
        rg: order.rg || undefined,
        cnpj: order.cnpj || undefined,
        nameCompany: order.nameCompany || undefined,
        state: order.state || undefined,
        city: order.city || undefined,
        father: order.father || undefined,
        mother: order.mother || undefined,
        birth: order.birth || undefined,
        request: request.request,
      })),
    };

    const storage = JSON.parse(localStorage.getItem('@order-cart')) || [];

    const updatedStorage = Array.isArray(storage) ? storage : [storage];

    updatedStorage.push(ordersMeta);

    localStorage.setItem('@order-cart', JSON.stringify(updatedStorage));

    console.log(updatedStorage);

    navigate('/cart');
  };

  return (
      <Screen>
        <Menu />

        <Container>

          <Header text="Dados do pedido" />

          <Center>
            <styles.ContainerSeparet>

              <styles.Forms>

                <ModalInput
                  name="Nome para o seu pedido"
                >
                  <Input
                    value={nameOrder}
                    onChange={(e) => setNameOrder(e.target.value)}
                    placeholder="Digite um nome para seu pedido"
                  />
                </ModalInput>

                <styles.Orders>
                  <P bold>Escolher pessoas para a certidão</P>

                  {ordersLocal && ordersLocal.length > 0 && ordersLocal.map((element, index) => (
                    <styles.Order key={index}>
                      <styles.OrderToggle>
                        <Span>
                          Pedido
                          {' '}
                          {index + 1}
                        </Span>
                        <div>
                          {index !== 0 && (
                            <button type="button" onClick={() => removeOrder(index)}>
                              <Trash size="20" />
                            </button>
                          )}
                          <button type="button">
                            <ArrowDown2 size="20" />
                          </button>
                        </div>
                      </styles.OrderToggle>

                      <styles.OnChangeUser change={element.change}>
                        <button type="button" onClick={() => handleChangeType(index, 1)}>Cpf</button>
                        <button type="button" onClick={() => handleChangeType(index, 2)}>Cnpj</button>
                      </styles.OnChangeUser>

                      <styles.GroupInputs>

                        <div className="group-row">
                          <ModalInput name="Estado do documento">
                            <Select
                              onChange={(e) => handleStateChange(e, index)}
                              value={element.state}
                            >
                              <option value="" disabled>Selecione um Estado</option>

                              {states && states.map((state) => (
                                <option key={state.id} value={state.sigla}>{state.nome}</option>
                              ))}
                            </Select>
                          </ModalInput>
                          <ModalInput name="Cidade do documento">
                            <Select
                              onChange={(e) => handleCityChange(e, index)}
                              value={element.city}
                            >
                              <option value="" disabled>Selecione uma Cidade</option>

                              {cities[element.state] && cities[element.state].map((city) => (
                                <option key={city.id} value={city.nome}>{city.nome}</option>
                              ))}
                            </Select>
                          </ModalInput>
                        </div>

                        {element.change === 1 ? (
                          <>
                            <div className="group-row">
                              <ModalInput name="CPF">
                                <Input
                                  value={element.cpf}
                                  placeholder="Digite o CPF"
                                  onChange={(e) => handleInputChange(e, index, 'cpf')}
                                  type="text"
                                />
                              </ModalInput>
                              <ModalInput name="RG">
                                <Input
                                  value={element.rg}
                                  placeholder="Digite o RG"
                                  onChange={(e) => handleInputChange(e, index, 'rg')}
                                  type="text"
                                />
                              </ModalInput>
                            </div>
                            <ModalInput name="Nome da pessoa">
                              <Input
                                value={element.name}
                                placeholder="Digite o nome da pessoa"
                                onChange={(e) => handleInputChange(e, index, 'name')}
                                type="text"
                              />
                            </ModalInput>

                            <div className="group-row">
                              <ModalInput name="Nome da mãe">
                                <Input
                                  value={element.mother}
                                  placeholder="Digite o nome da mãe"
                                  onChange={(e) => handleInputChange(e, index, 'mother')}
                                  type="text"
                                />
                              </ModalInput>

                              <ModalInput name="Nome do pai">
                                <Input
                                  value={element.father}
                                  placeholder="Digite o nome do pai"
                                  onChange={(e) => handleInputChange(e, index, 'father')}
                                  type="text"
                                />
                              </ModalInput>
                            </div>

                            <ModalInput name="Data de nascimento">
                              <Input
                                value={element.birth}
                                placeholder="Digite a data de nascimento"
                                onChange={(e) => handleInputChange(e, index, 'birth')}
                                type="text"
                              />
                            </ModalInput>
                          </>
                        ) : (
                          <>
                            <ModalInput name="CNPJ">
                              <Input
                                value={element.cnpj}
                                placeholder="Digite o CNPJ"
                                onChange={(e) => handleInputChange(e, index, 'cnpj')}
                                type="text"
                              />
                            </ModalInput>
                            <ModalInput name="Nome da empresa">
                              <Input
                                value={element.nameCompany}
                                placeholder="Digite o nome da empresa"
                                onChange={(e) => handleInputChange(e, index, 'nameCompany')}
                                type="text"
                              />
                            </ModalInput>
                          </>
                        )}

                      </styles.GroupInputs>
                    </styles.Order>
                  ))}

                  <button type="button" onClick={addNewOrder}>Adicionar mais</button>

                </styles.Orders>
              </styles.Forms>

              <styles.OrderList>
                <P bold>Dados do pedido</P>

                <Span>{nameOrder}</Span>

                {ordersLocal && ordersLocal.length > 0
                  && ordersLocal.map((order) => (
                    <styles.OrderListContent key={order.id}>
                      <li>
                        Nome:
                        {' '}
                        <b>{order.change === 1 ? order.nomePessoa : order.nomeEmpresa}</b>
                      </li>

                      <li>
                        Documento:
                        {' '}
                        <b>{order.change === 1 ? order.cpf : order.cnpj}</b>
                      </li>

                      <li>
                        Estado:
                        {' '}
                        <b>{order.state}</b>
                      </li>

                      <li>
                        Cidade:
                        {' '}
                        <b>{order.city}</b>
                      </li>
                    </styles.OrderListContent>
                  ))}

                <button type="button" onClick={handleSubmit}>
                  Continuar
                  <ArrowRight size={24} />
                </button>
              </styles.OrderList>
            </styles.ContainerSeparet>
          </Center>

          <Footer />
        </Container>
      </Screen>
  );
}
